

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCar } from '../Slice/carsSlice';
import { server } from "../config";

const SelectCab = ({ state, dispatch }) => {
    const dispatchRedux = useDispatch();
    const cabs = useSelector((state) => state.Cabs.Cabs || []);
    const [selectedCarModel, setSelectedCarModel] = useState("");
    const [selectedDescription, setSelectedDescription] = useState("");
    const [selectedFare, setSelectedFare] = useState("");
    const [filteredCabs, setFilteredCabs] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // Filter cabs whenever trip type changes
        filterCabsByCategory(state.triptype);
    }, [state.triptype, cabs]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${server}/api/v1/allCars`);
            dispatchRedux(getAllCar(response.data));
        } catch (error) {
            console.error('Error fetching cars:', error.message);
        }
    };

    const filterCabsByCategory = (tripType) => {
        // Reset selections when trip type changes
        setSelectedCarModel("");
        setSelectedDescription("");
        setSelectedFare("");
        
        // Filter cabs based on trip type
        const filtered = cabs.filter(cab => {
            return cab.category.some(category => {
                // Match frontend trip types with API categories
                switch(tripType) {
                    case "Drop Trip":
                        return category === "Drop Trip";
                    case "Local Trip":
                        return category === "Local Trip";
                    case "Outstation":
                        return category === "Outstation";
                    default:
                        return false;
                }
            });
        });
        
        setFilteredCabs(filtered);
    };

    const handleCarModelChange = (e) => {
        const carModel = e.target.value;
        setSelectedCarModel(carModel);
        const selectedCab = cabs.find((cab) => cab._id === carModel);
        setSelectedDescription(selectedCab ? selectedCab.description : "No description available");
        
        dispatch({ type: "SELECTED-CAB", payload: selectedCab });
    };

    return (
        <div>
            <div className='row mb-3'>
                <div className="col-6">
                    <label htmlFor="SelectCab">Select Cab</label> <br />
                    <select
                        id="carModelDropdown"
                        value={selectedCarModel}
                        onChange={handleCarModelChange}
                        className="car-model-dropdown"
                        required
                    >
                        <option value="">-- Select Car Model --</option>
                        {filteredCabs.map((cab) => (
                            <option key={cab._id} value={cab._id}>
                                {cab.carModel} ({cab.brand}) - {cab.seats} Seater
                            </option>
                        ))}
                    </select>
                </div>
                <div className='col-6'>
                    <label htmlFor="Fare">Fare (Rs)</label>
                    <input 
                        type="text" 
                        className='input-field'
                        id='Fare'
                        value={state.fare}
                        readOnly
                    />
                </div>
            </div>
            
            <div className='row mb-3'>
                <div className="col-12">
                    <label htmlFor="returnLocation">Description</label>
                    <p className='fare-explanation' style={{color: "black"}}>
                        {selectedDescription}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SelectCab;
